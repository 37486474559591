"use client";

import React, { useEffect, useRef, useState } from "react";
import styles from "./FlipCard.module.scss";
import { motion, useSpring } from "framer-motion";

const spring = {
  type: "spring",
  stiffness: 300,
  damping: 40,
};

/**
 *
 * FlipCard
 *
 */
const FlipCard = ({
  className = "",
  data = [],
  children = undefined,
  cardTitle = "",
  cardSubtitle = "",
  value = "",
  canFlip = true,
  align = "left",
  color = "white", // 'color' || black
  flipped = false,
  type = "",
  onFlip = (value) => {},
  limitFlips = 2,
  timesFlipped = 0,
  slug = "",
  size = "default",
  unlocked = true,
  caption = "",
  ...props
}) => {
  const [title, setTitle] = useState("");
  const [number, setNumber] = useState("");
  function randomInteger(min = 0, max = 0) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const [rotateXaxis, setRotateXaxis] = useState(0);
  const [rotateYaxis, setRotateYaxis] = useState(0);
  const ref = useRef(null);

  const getTitle = () => {
    if (type !== "image") {
      const cardIdx = randomInteger(0, data?.length - 1);
      const title = data[cardIdx];
      setTitle(title);
      onFlip(title);
    } else {
      const cardIdx = randomInteger(2, data?.numberOfCards);
      const number = cardIdx < 10 ? `0${cardIdx}` : `${cardIdx}`;
      onFlip(number);
      setNumber(number);
    }
  };

  const flipCard = (showFlip = true) => {
    if (showFlip) {
      flipped = !flipped;
    }
    if (canFlip) {
      getTitle();
    }
  };

  const dx = useSpring(0, spring);
  const dy = useSpring(0, spring);

  useEffect(() => {
    dx.set(-rotateXaxis);
    dy.set(rotateYaxis);
  }, [rotateXaxis, rotateYaxis]);

  return (
    <div className={`${styles["card-outer"]} flip-card`}>
      <motion.article
        className={`${styles.card} pointer-events-all ${className} ${
          styles[`color-${color}`]
        } mb-5 ${styles[`size-${size}`]} ${flipped ? styles.active : ""} ${
          !unlocked ? styles.locked : ""
        }`}
        data-align={align}
        onClick={() => {
          if (!canFlip) return;
          flipCard(true);
        }}
        onBlur={() => {}}
        onFocus={() => {}}
      >
        <motion.div
          className={styles["card-body"]}
          transition={spring}
          animate={{ rotateY: flipped ? 180 : 0 }}
        >
          <div className={styles["card-front"]}>
            <h2
              className={
                color === "white" ? "text-black" : "text-white mb-1 capitalize"
              }
            >
              {color === "color" ? (
                <>
                  <span className="compress">W</span>ait...
                  <br />
                  Why?
                </>
              ) : (
                <>
                  <span className="compress">W</span>hat
                  <br />
                  If?
                </>
              )}
            </h2>
          </div>
          <div className={styles["card-back"]}>
            {type !== "image" ? (
              <h2 className="text-black mb-1 capitalize title compress">
                {title || cardTitle}
              </h2>
            ) : (
              <>
                {value || number ? (
                  <img
                    src={`/cards/WHATIF_B_American-poker-size-${
                      value || number
                    }.jpg`}
                    alt=""
                  />
                ) : null}
              </>
            )}
          </div>
        </motion.div>
        {/* {subTitle || cardSubtitle ? <p>{subTitle || cardSubtitle}</p> : null}
		{children ? children : null} */}
      </motion.article>

      {canFlip ? (
        <>
          {!flipped ? (
            <p className="text-current">
              Click the card to flip.
              {/* {timesFlipped} of 2 (Limit 2 flips) */}
            </p>
          ) : (
            <div className="flex items-center gap-2">
              {type !== "waitWhy" ? (
                <span className="text-current text-base leading-none">
                  Click the card to flip again.
                  {/* {timesFlipped} of 2 (Limit 2 flips) */}
                </span>
              ) : null}
              {flipped ? (
                <span>
                  <button
                    className="text-current text-base leading-none p-0 h-auto"
                    onClick={async () => {
                      flipCard(false);
                    }}
                  >
                    Flip again
                  </button>
                </span>
              ) : null}
            </div>
          )}
        </>
      ) : null}

      {caption ? (
        <p className="text-gray">Sall-e's Caption: {caption}</p>
      ) : null}
    </div>
  );
};

export default FlipCard;
